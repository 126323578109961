import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {IntlProvider, addLocaleData} from "react-intl";

import './index.css';
import * as serviceWorker from './serviceWorker';

import store from './store';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";

addLocaleData([...fr, ...en]);

const localData = {
    'fr': messages_fr,
    'en': messages_en
};

const language = navigator.language.split(/[-_]/)[0] === 'fr' ? 'fr' : 'en';
const messages = localData[language];

ReactDOM.render(
  <IntlProvider locale={language} messages={messages}>
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
