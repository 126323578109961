import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import potReducer from './pot';
import balanceSheetReducer from './balanceSheet';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  potState: potReducer,
  balanceSheetState: balanceSheetReducer
});

export default rootReducer;
