import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import { FormattedMessage } from 'react-intl';
import { withAuthorization } from '../Session';

class UserChildren extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.children.length) {
      this.setState({ loading: true });
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/user/${this.props.uid}/children`, {
      headers: {
        'id-token': this.props.authUser.idToken,
      },
    }).finally(() => this.setState({isLoading: false}))
    .then(function (response) {
        return response.json();
    })
    .then(users => {
      this.props.onSetChildren(users.users);
    })
    .finally(() => this.setState({loading: false}))

  }


  render() {
    const users = this.props.children;
    const { loading } = this.state;

    return (
      <div>
        {loading ? <div>Loading ...</div> :

          <table className="table-responsive table table-striped">
            <thead className="thead-dark">
                <tr>
                  <th><FormattedMessage id="app.userList.id" defaultMessage="ID"/></th>
                  <th><FormattedMessage id="app.userList.email" defaultMessage="E-Mail"/></th>
                  <th><FormattedMessage id="app.userList.role" defaultMessage="Role"/></th>
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.USER_LEVEL_1) && <th><FormattedMessage id="app.userList.code" defaultMessage="Code"/></th>}
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <th><FormattedMessage id="app.userList.region" defaultMessage="Region"/></th>}
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <th><FormattedMessage id="app.userList.commission" defaultMessage="Commission"/></th>}
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <th><FormattedMessage id="app.userList.canCreateUser" defaultMessage="Can create user ?"/></th>}
                  <th><FormattedMessage id="app.userList.firstname" defaultMessage="Firstname"/></th>
                  <th><FormattedMessage id="app.userList.lastname" defaultMessage="lastname"/></th>
                </tr>
            </thead>
            <tbody>
              {users.length ===0 && <tr><td colSpan="99"><FormattedMessage id="app.userList.noElement" defaultMessage="No element"/></td></tr>}
              {users.map(user => (
                <tr key={user.uid}>
                  <td>
                    <a href={`/user/show/${user.uid}`}>{user.uid}</a> 
                  </td>
                  <td>
                    {user.email}
                  </td>
                  <td>
                    {user.role}
                  </td>
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.USER_LEVEL_1) && <td>
                    {user.code}
                  </td> }
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <td>
                    {user.region}
                  </td> }
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <td>
                    {user.commission > 0 && this.props.parent.amount > 0 ? <span>{Number.parseFloat(Number.parseFloat(user.commission) * Number.parseFloat(this.props.parent.amount) / 100).toFixed(2)} &euro;</span> : ''}
                  </td> }
                  {(this.props.parent.role === ROLES.SUPER_USER || this.props.parent.role === ROLES.ADMIN) && <td>
                    {user.canCreateUser ? <FormattedMessage id="app.userList.yes" defaultMessage="Yes"/> : <FormattedMessage id="app.userList.no" defaultMessage="No"/>}
                  </td> }
                  <td>
                    {user.firstname}
                  </td>
                  <td>
                    {user.lastname}
                  </td>
                </tr>
              ))}
            </tbody>
          </table> }
      </div>
    );
  }
}




const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  children: Object.keys(state.userState.children || {}).map(key => ({
    ...state.userState.children[key],
    uid: key,
  })),
});

const mapDispatchToProps = dispatch => ({
  onSetChildren: children => dispatch({ type: 'CHILDREN_SET', children }),
});

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserChildren);
