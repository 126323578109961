import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { DateRangePicker } from 'react-dates';
import * as ROLES from '../../constants/roles';
import { FormattedMessage } from 'react-intl';

class BalanceSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      loading: false,
      total: 0,
      usersToshow: {}
    };

    this.loadBalanceSheet.bind(this);
    this.pay.bind(this);
  }

  componentDidMount() {
    this.loadBalanceSheet();
  }

  loadBalanceSheet() {
    this.setState({ loading: true });

    fetch(`${process.env.REACT_APP_BASE_URL}/balanceSheet`, {
      headers: {
        'id-token': this.props.authUser.idToken,
      },
    })
    .then(function (response) {
        return response.json();
    })
    .then(balanceSheet => {
      this.props.onSetBalanceSheet(balanceSheet.users);
    })
    .finally(() => this.setState({loading: false}))
  }


  pay(user) {
    if (window.confirm(`Do you confirm the payment of ${user.amount} euros to user ${user.email} `)) {
      this.setState({loading: true});
      fetch(`${process.env.REACT_APP_BASE_URL}/balanceSheet/pay/${user.uid}`, {
          method: 'POST',
          headers: {
            'id-token': this.props.authUser.idToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({pot: user.potFiltered, amount: user.amount})
      }).then(function () {}).finally(() => {
        this.setState({loading: false});
        this.loadBalanceSheet();
      });  
    }
  }

  show(user) {
    let usersToShow = this.state.usersToshow;
    usersToShow[user.uid] = usersToShow[user.uid] ? !usersToShow[user.uid] : true;
    this.setState({usersToShow});
  }


  

  render() {
    let { users } = this.props;
    let total = 0;
    let totalUser = 0;

    const { loading } = this.state;
    let usersFiltered = [];

    if (users.length > 0) {
      users.map(user => {
        user.potFiltered = Object.keys(user.pot).filter(userId => {
          return true === user.pot[userId].isValidated 
            && (! user.pot[userId].hasOwnProperty('isRefunded') || false === user.pot[userId].isRefunded) 
            && (! user.pot[userId].hasOwnProperty('isInTrialPeriod') || false === user.pot[userId].isInTrialPeriod) 
            && (this.state.startDate ? user.pot[userId].createdAt.substring(0, 10) >= this.state.startDate.format('YYYY-MM-DD') : true) 
            && (this.state.endDate ? user.pot[userId].createdAt.substring(0, 10) <= this.state.endDate.format('YYYY-MM-DD') : true);
        });

        return user;
      });

      usersFiltered = users.filter(user => { return user.potFiltered.length > 0} );
      usersFiltered.map(user => {
        totalUser = 0;
        user.potFiltered.map(modelId => {
          totalUser=totalUser+parseFloat(user.pot[modelId].amount);
          total=total+parseFloat(user.pot[modelId].amount);

          return user.pot[modelId];
        }); 
        user.amount = totalUser;

        return user;
      });
    }

    return (
      <div>
        <h1><FormattedMessage id="app.balanceSheet.balanceSheet" defaultMessage="Balance sheet"/></h1>
        {loading ? <div>Loading ...</div> :
          <div>
              <div className="col-md-12">
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start_date_id" // PropTypes.string.isRequired,
                displayFormat={() => "DD/MM/YYYY"}
                isOutsideRange={() => false}
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                    this.setState({ startDate, endDate });
                  }
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              /><br/><br/>
            </div>

            <table className="table table-striped">
              <thead className="thead-dark">
                  <tr>
                    <th><FormattedMessage id="app.balanceSheet.id" defaultMessage="ID"/></th>
                    <th><FormattedMessage id="app.balanceSheet.email" defaultMessage="E-Mail"/></th>
                    <th><FormattedMessage id="app.balanceSheet.role" defaultMessage="Role"/></th>
                    <th><FormattedMessage id="app.balanceSheet.iban" defaultMessage="IBAN"/></th>
                    <th><FormattedMessage id="app.balanceSheet.bic" defaultMessage="BIC"/></th>
                    <th><FormattedMessage id="app.balanceSheet.amount" defaultMessage="Amount"/></th>
                    <th><FormattedMessage id="app.balanceSheet.action" defaultMessage="Action"/></th>
                  </tr>
              </thead>
              <tbody>
                {usersFiltered.length === 0 && <tr><td colSpan="8"><FormattedMessage id="app.balanceSheet.noElement" defaultMessage="No element"/></td></tr>}
                {usersFiltered.map((user) => (
                  <React.Fragment key={user.uid}>
                    <tr>
                      <td>
                        {user.uid}
                      </td>
                      <td>
                        {user.email}
                      </td>
                      <td>
                        {user.role}
                      </td>
                      <td>
                        {user.iban}
                      </td>
                      <td>
                        {user.bic}
                      </td>
                      <td>
                        {Number.parseFloat(user.amount).toFixed(2)} &euro;
                      </td>
                      <td>
                        <button className="btn btm-sm btn-primary" onClick={this.pay.bind(this, user)}><FormattedMessage id="app.balanceSheet.pay" defaultMessage="Pay"/></button>
                        <button className="btn btm-sm btn" onClick={this.show.bind(this, user)}>{user.potFiltered.length} <FormattedMessage id="app.balanceSheet.participation" defaultMessage="participation(s)"/></button>
                      </td>
                    </tr>
                    {this.state.usersToShow && this.state.usersToShow[user.uid] === true && <tr className="table-info">
                      <td colSpan="99" >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Prénom</th>
                              <th>Nom</th>
                              <th>Montant</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.potFiltered.map(modelId => 
                              <tr>
                                <td>{user.pot[modelId].createdAt}</td>                       
                                <td>{user.pot[modelId].data.firstName}</td>
                                <td>{user.pot[modelId].data.lastName}</td>
                                <td>{Number.parseFloat(user.pot[modelId].amount).toFixed(2)} &euro;</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>}
                    </React.Fragment>
                ))}
                

              </tbody>
            </table>

            {usersFiltered.length > 0 && <div class='totalnum'><span>Total:</span><span>{Number.parseFloat(total).toFixed(2)} &euro;</span></div>}
            
          </div> }
        </div>
    );
  }
}


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  users: Object.keys(state.balanceSheetState.users || {}).map(key => ({
    ...state.balanceSheetState.users[key],
    uid: key,
  })),
});

const mapDispatchToProps = dispatch => ({
  onSetBalanceSheet: users => dispatch({ type: 'BALANCE_SHEET_SET', users }),
});

const condition = authUser =>
  authUser && (authUser.role === ROLES.ADMIN || authUser.role === ROLES.ACCOUNTING);

export default compose(
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BalanceSheet);

