import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import { FormattedMessage } from 'react-intl';


class HomePage extends Component {
  
  render() {
    return (
      <div>
        <h1><FormattedMessage id="app.home.homePage" defaultMessage="Home page"/></h1>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(HomePage);
