import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { PasswordForgetForm } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { FormattedMessage } from 'react-intl';

class SignInPageBase extends Component {
  render() {
    return (
      <div>
        {this.props.authUser && <Redirect to="/home"/>}

        <img class='logoform' src='/logo.png' />
        
        <SignInForm />
    
        <h4><FormattedMessage id="app.SignInPageBase.forgetYourPassword" defaultMessage="Forget your password"/></h4>
        <PasswordForgetForm />
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.POT);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
    <div class="inscrip">
    <div>
    <img src="/img_co.jpg" />
    </div>
    <div>
      <form onSubmit={this.onSubmit}>
        <h2><FormattedMessage id="app.SignInPageBase.signIn" defaultMessage="SignIn"/></h2>
        <div className="form-group">
          <label><FormattedMessage id="app.signInFormBase.emailAddress" defaultMessage="Email Address"/></label>
          <input
            className="form-control"
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
        </div>

        <div className="form-group">
          <label><FormattedMessage id="app.signInFormBase.password" defaultMessage="Password"/></label>
          <input
            className="form-control"
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
          />
        </div>
        <button disabled={isInvalid} type="submit" className="btn btn-primary">
          <FormattedMessage id="app.SignInPageBase.signIn" defaultMessage="Sign In"/>
        </button>

        {error && <p>{error.message}</p>}
      </form>
      </div>
      </div>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const SignInPage = compose(
  connect(
    mapStateToProps,
  ),
)(SignInPageBase);

export default SignInPage;

export { SignInForm };
