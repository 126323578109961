import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { FormattedMessage } from 'react-intl';

const Navigation = ({ authUser }) =>
  authUser ? (
    <NavigationAuth authUser={authUser} />
  ) : (
    <NavigationNonAuth />
  );

const NavigationAuth = ({ authUser }) => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <a className="navbar-brand" href="/"><img src='/logo.png' /></a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        {authUser.role === ROLES.USER_LEVEL_1 && authUser.canCreateUser && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.USER_LEVEL_2_CREATE}><FormattedMessage id="app.navigation.create" defaultMessage="Create"/></Link>
          </li>
        )}

        {authUser.role === ROLES.USER_LEVEL_1 && authUser.canCreateUser && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.USER_LEVEL_2_LIST}><FormattedMessage id="app.navigation.list" defaultMessage="List"/></Link>
          </li>
        )}

        {authUser.role === ROLES.SUPER_USER && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.USER_LEVEL_1_CREATE}><FormattedMessage id="app.navigation.create" defaultMessage="Create"/></Link>
          </li>
        )}

        {authUser.role === ROLES.SUPER_USER && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.USER_LEVEL_1_LIST}><FormattedMessage id="app.navigation.list" defaultMessage="List"/></Link>
          </li>
        )}

        {authUser.role === ROLES.ADMIN && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.SUPER_USER_CREATE}><FormattedMessage id="app.navigation.create" defaultMessage="Create"/></Link>
          </li>
        )}

        {authUser.role === ROLES.ADMIN && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.SUPER_USER_LIST}><FormattedMessage id="app.navigation.list" defaultMessage="List"/></Link>
          </li>
        )}

        {(authUser.role === ROLES.ADMIN || authUser.role === ROLES.ACCOUNTING) && (
          <li className="nav-item">
            <Link className="nav-link active" to={ROUTES.BALANCE_SHEET}><FormattedMessage id="app.navigation.balancSheet" defaultMessage="Balance sheet"/></Link>
          </li>
        )}
      </ul>

      <span className="navbar-text">
        <ul className="navbar-nav mr-auto">
        {authUser.role !== ROLES.ADMIN && <li>
            <Link className="nav-link active" to={ROUTES.POT}><FormattedMessage id="app.navigation.myPot" defaultMessage="My pot"/></Link>
          </li> }

          <li>
            <Link className="nav-link active" to={ROUTES.ACCOUNT}><FormattedMessage id="app.navigation.myAccount" defaultMessage="My account"/></Link>
          </li>

          <li className="nav-item">
            <SignOutButton />
          </li>
        </ul>
      </span>
    </div>
  </nav>

);

const NavigationNonAuth = () => (
  <ul className="nav"></ul>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);
