export const SIGN_UP = '/signup';
export const SIGN_IN = '/';
export const HOME = '/home';
export const POT = '/pot';
export const BALANCE_SHEET = '/balance-sheet';
export const USER_SHOW = '/user/show/:id';
export const SUPER_USER_CREATE = '/super-user/create';
export const USER_LEVEL_1_CREATE = '/user-level-1/create';
export const USER_LEVEL_2_CREATE = '/user-level-2/create';
export const SUPER_USER_LIST = '/super-user/list';
export const USER_LEVEL_1_LIST = '/user-level-1/list';
export const USER_LEVEL_2_LIST = '/user-level-2/list';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
