import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import PasswordChangeForm from '../PasswordChange';
import { FormattedMessage } from 'react-intl';

class AccountPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      parent: null,
    };
  }

  componentDidMount() {
    this.loadParent();
  }

  loadParent() {
    this.setState({ loading: true });

    fetch(`${process.env.REACT_APP_BASE_URL}/user/parent`, {
      headers: {
        'id-token': this.props.authUser.idToken,
      },
    }).finally(() => this.setState({isLoading: false}))
    .then(function (response) {
        return response.json();
    })
    .then(user => {
      this.setState({parent: user.user});
    })
    .finally(() => this.setState({loading: false}))
  }

  render() {
    const authUser = this.props.authUser;

    return (
      <div>
      <div className="jumbotron">
        <h1><FormattedMessage id="app.account.account" defaultMessage="Account"/>: {authUser.email}</h1>
        { authUser.code && <h3><FormattedMessage id="app.account.code" defaultMessage="Code"/> {authUser.code}</h3> }
        { this.state.parent && this.state.parent.amount > 0 && authUser.commission > 0 && <h3><FormattedMessage id="app.account.commission" defaultMessage="Commission"/> <span>{Number.parseFloat(Number.parseFloat(authUser.commission) * Number.parseFloat(this.state.parent.amount) / 100).toFixed(2)} &euro;</span> </h3> }
        { authUser.amount > 0 && <h3><FormattedMessage id="app.account.amount" defaultMessage="Amount"/> <span>{Number.parseFloat(authUser.amount).toFixed(2)} &euro;</span> </h3> }
      </div>
      <PasswordChangeForm />
    </div>
    );
  }
}


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition),
)(AccountPage);
