import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { UserList } from '../Users';
import { FormattedMessage } from 'react-intl';

const ListSuperAdminPage = () => (
  <div>
    <h1><FormattedMessage id="app.superUser.listSuperUser" defaultMessage="List"/></h1>
    <UserList role={ROLES.SUPER_USER}/>
  </div>
);

const condition = authUser =>
  authUser && authUser.role === ROLES.ADMIN;

export default compose(
  withAuthorization(condition),
)(ListSuperAdminPage);
