import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import PotPage from '../Account/PotPage';
import AccountPage from '../Account';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import {CreateUserLevel1Page, ListUserLevel1Page} from '../UserLevel1';
import {CreateUserLevel2Page, ListUserLevel2Page} from '../UserLevel2';
import {CreateSuperUserPage, ListSuperUserPage} from '../SuperUser';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { UserItem } from '../Users';
import BalanceSheet from '../BalanceSheet';

const App = () => (
  <Router>
    <div>
      <header>
        <Navigation />
      </header>

      <div className="container">
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.POT} component={PotPage} />
        <Route path={ROUTES.BALANCE_SHEET} component={BalanceSheet} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.USER_SHOW} render={(props) => <UserItem {...props} />} />
        <Route path={ROUTES.SUPER_USER_CREATE} component={CreateSuperUserPage} />
        <Route path={ROUTES.USER_LEVEL_1_CREATE} component={CreateUserLevel1Page} />
        <Route path={ROUTES.USER_LEVEL_2_CREATE} component={CreateUserLevel2Page} />
        <Route path={ROUTES.SUPER_USER_LIST} component={ListSuperUserPage} />
        <Route path={ROUTES.USER_LEVEL_1_LIST} component={ListUserLevel1Page} />
        <Route path={ROUTES.USER_LEVEL_2_LIST} component={ListUserLevel2Page} />
      </div>
    </div>
  </Router>
);

export default withAuthentication(App);
