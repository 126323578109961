import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import app from 'firebase/app';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import 'abortcontroller-polyfill';
import IBAN from 'iban';
import { FormattedMessage } from 'react-intl';
import { withAuthorization } from '../Session';

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  loading: false,
  iban: '',
  bic: '',
  error: null,
  message: null,
  canCreateUser: false,
  firstname: '',
  lastname: '',
  region: '',
  amount: 15,
  commissions: [0, 15, 30, 45, 60],
  commission: null,
  referralCode: '',
  errorReferralCode: null,
  errorEmail: null,
  classReferralCode: 'form-control',
  classEmail: 'form-control',
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `An account with this E-Mail address already exists.`;

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.controller = null;
    this.signal = null;

    this.checkReferralCode.bind(this);
    this.onDeleteCommissions = this.onDeleteCommissions.bind(this);
    this.onAddCommissions = this.onAddCommissions.bind(this);

    this.state = { ...INITIAL_STATE };
  }


  componentDidMount() {
    this.secondaryApp = app.initializeApp(config, "Secondary");
  }

  componentWillUnmount() {
    this.secondaryApp.delete();
  }


  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({loading: true});
    window.scrollTo(0, 0);

    const { email, passwordOne, iban, bic, canCreateUser, firstname, lastname, region, amount, commissions, commission, referralCode } = this.state;
    const { role } = this.props;

    if (this.checkReferralCode) {
      this.checkReferralCode(referralCode).then(response => {
        this.setState({errorReferralCode: null, classReferralCode: 'form-control'});

        this.secondaryApp
          .auth()
          .createUserWithEmailAndPassword(email, passwordOne)
          .then(authUser => {
            // Create a user in your Firebase realtime database
            return this.secondaryApp.database().ref(`users/${authUser.user.uid}`).set({
              parent: this.props.authUser.uid, 
              email,
              role,
              iban: this.props.role === ROLES.SUPER_USER || this.props.role === ROLES.USER_LEVEL_1 ? iban: null,
              bic: this.props.role === ROLES.SUPER_USER || this.props.role === ROLES.USER_LEVEL_1 ? bic: null,
              canCreateUser, 
              firstname, 
              lastname, 
              region, 
              amount: this.props.role === ROLES.SUPER_USER ? Number.parseFloat(amount): null,
              commissions: this.props.role === ROLES.SUPER_USER ? commissions: null,
              commission: this.props.role === ROLES.USER_LEVEL_1 ? Number.parseFloat(commission): null,
              code: this.props.role !== ROLES.SUPER_USER ? referralCode.toLowerCase(): null,
            });
          })
          .then(() => {
            this.secondaryApp.auth().signOut();
            this.setState({ ...INITIAL_STATE });
            this.setState({message: 'User has been created'});
          })
          .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              this.setState({errorEmail: ERROR_MSG_ACCOUNT_EXISTS, classEmail: 'form-control is-invalid'});
            }
    
          }).finally(() => this.setState({loading: false}));
        }).catch(error => {
          this.setState({errorReferralCode: error, classReferralCode: 'form-control is-invalid'});
        }).finally(() => this.setState({loading: false}));
    }
    

    
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCommissions(i, e) {
    let commissions = this.state.commissions;

    if (e.target.value >= 0 && e.target.value <= 100) {
      commissions[i] = Number.parseFloat(e.target.value);
    }

    this.setState({commissions});
  }

  onDeleteCommissions(i) {
    let commissions = this.state.commissions;
    commissions.splice(i, 1);

    this.setState({commissions});
  }

  onAddCommissions(i) {
    let commissions = this.state.commissions;
    commissions.push(0);

    this.setState({commissions});
  }

  onChangeReferralCode = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  checkReferralCode(referralCode) {
    return new Promise((resolve, reject) => {
      if (this.props.role === ROLES.SUPER_USER) {
        resolve();
      }

      if (!referralCode) {
        reject('Code missing'); 
      } else {
        fetch(`${process.env.REACT_APP_BASE_URL}/referralCode/isvalid/${referralCode}`, {
          method: 'GET',
          headers: {
            'id-token': this.props.authUser.idToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            reject('Internal server error');
          })
          .then((response) => {
            if (response) {
              if (!response.isValid) {
                reject('Code already used');  
              } else {
                resolve();
              }
            }
          }).catch(err => {
            if (err.name === 'AbortError') {
              resolve();
            }
          })
      }     
    });
  }

  render() {
    const {
      email,
      passwordOne,
      passwordTwo,
      iban,
      bic,
      error,
      canCreateUser, 
      firstname, 
      lastname, 
      region, 
      amount, 
      commissions,
      commission,
      referralCode
    } = this.state;

    let classIban = iban && !IBAN.isValid(iban) ? 'form-control is-invalid' : 'form-control';
    let classEmail = (email && !this.validateEmail(email)) || this.state.errorEmail ? 'form-control is-invalid' : 'form-control';

    let isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      (referralCode === '' || this.state.errorReferralCode !== null) && (this.props.role === ROLES.USER_LEVEL_2 || this.props.role === ROLES.USER_LEVEL_1 ) ||
      firstname === '' || 
      lastname === '' || 
      region === '' || 
      ((amount === '' || amount <= 0) && this.props.role === ROLES.SUPER_USER ) ||
      email === '' ||
      commission === null && this.props.role === ROLES.USER_LEVEL_1 ||
      !this.validateEmail(email) ||
      ((iban === '' || !IBAN.isValid(iban)) && this.props.role !== ROLES.USER_LEVEL_2) ||
      (bic === '' && this.props.role !== ROLES.USER_LEVEL_2);
    let commissionsArray = [];
    for (let i = 0 ; i < commissions.length ; i++) {
      commissionsArray.push(
        <div className="form-row" key={i}>
            <div className="col-md-1 mb-3">
              <input 
                className="form-control"
                type='number' 
                min="0" max="100"
                value={commissions[i]} 
                name={commissions[i]} 
                onChange={this.onChangeCommissions.bind(this, i)} />
            </div>
            <div className="col-md-1 mb-3">%</div>
            <div className="col-md-4 mb-3">
              <b>{Number.parseFloat(Number.parseFloat(amount)*(100-Number.parseFloat(commissions[i]))/100).toFixed(2)} euros</b> <FormattedMessage id="app.signUp.toSuperUserAnd" defaultMessage="to super user and"/> <b>{Number.parseFloat(Number.parseFloat(amount)*Number.parseFloat(commissions[i])/100).toFixed(2)} euros</b> <FormattedMessage id="app.signUp.toUser" defaultMessage="to user"/>
            </div>
            <div className="col-md-1 mb-3">
              <button type="button" className="btn btn-danger" onClick={this.onDeleteCommissions.bind(this, i)}><FormattedMessage id="app.signUp.delete" defaultMessage="Delete"/></button>
            </div>
        </div>
      );
    }


    let commissionsSelect = [];
    commissionsSelect.push(
      <option>Commission</option>
    );
    if (this.props.authUser.commissions) {
      for (let i = 0 ; i < this.props.authUser.commissions.length ; i++) {
        commissionsSelect.push(
          <FormattedMessage id="app.signUp.commissionOption" defaultMessage="{amount1} euros to super user and {amount2} to user" values={{
              amount1: Number.parseFloat(Number.parseFloat(this.props.authUser.amount)*Number.parseFloat((100-this.props.authUser.commissions[i])/100)).toFixed(2),
              amount2: Number.parseFloat(Number.parseFloat(this.props.authUser.amount)*Number.parseFloat(this.props.authUser.commissions[i]/100)).toFixed(2)
          }}>
            {(message) => <option value={this.props.authUser.commissions[i]}>{message}</option>}
          </FormattedMessage>
        );
      }
    }

    return (
      <div>
        {this.state.message && <div className="alert alert-success" role="alert">{this.state.message}</div>}

        {this.state.loading === false ? <form onSubmit={this.onSubmit}>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.email" defaultMessage="Email"/>*</label>
                <input className={classEmail}
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                />
                <div className="invalid-feedback">{!this.validateEmail(email) ? 'Email invalid' : this.state.errorEmail}</div>
              </div>
            </div>

            <div className="col">
            {this.props.role !== ROLES.SUPER_USER && <div className="form-group">
                <label><FormattedMessage id="app.signUp.referralCode" defaultMessage="Referral code"/>*</label>
                <input className={this.state.classReferralCode}
                  name="referralCode"
                  value={referralCode}
                  onChange={this.onChangeReferralCode}
                  type="text"
                  pattern='[a-z0-9]{0,20}'
                />
                {this.state.errorReferralCode && <div className="invalid-feedback">{this.state.errorReferralCode}</div>}
              </div> }
            </div>
          </div>

          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.password" defaultMessage="Password"/>*</label>
                <input className="form-control"
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type="password"
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.passwordRepeat" defaultMessage="Password (repeat)"/>*</label>
                <input className="form-control"
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type="password"
                />
              </div>
            </div>
          </div>


          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.firstname" defaultMessage="Firstname"/>*</label>
                <input className="form-control"
                  name="firstname"
                  value={firstname}
                  onChange={this.onChange}
                  type="text"
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.lastname" defaultMessage="Lastname"/>*</label>
                <input className="form-control"
                  name="lastname"
                  value={lastname}
                  onChange={this.onChange}
                  type="text"
                />
              </div>
            </div>
          </div>


          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label><FormattedMessage id="app.signUp.region" defaultMessage="Region"/>*</label>
                <input className="form-control"
                  name="region"
                  value={region}
                  onChange={this.onChange}
                  type="text"
                />
              </div>
            </div>

            <div className="col">
              {this.props.role === ROLES.SUPER_USER && <div className="form-group">
                  <label><FormattedMessage id="app.signUp.amount" defaultMessage="Amount"/>*</label>
                  <input className="form-control"
                    name="amount"
                    value={amount}
                    onChange={this.onChange}
                    type="number"
                  />
              </div> }
            </div>
          </div>


          <div className="form-row">
            <div className="col">
              {this.props.role !== ROLES.USER_LEVEL_2 && <div className='form-group'>
                  <label><FormattedMessage id="app.signUp.iban" defaultMessage="IBAN"/>*</label>
                  <input className={classIban}
                    name="iban"
                    value={iban}
                    onChange={this.onChange}
                    type="text"
                  />
                  <div className="invalid-feedback"><FormattedMessage id="app.signUp.ibanInvalid" defaultMessage="IBAN invalid"/></div>

              </div> }
            </div>

            <div className="col">
              {this.props.role !== ROLES.USER_LEVEL_2 && <div className="form-group">
                <label><FormattedMessage id="app.signUp.bic" defaultMessage="BIC"/>*</label>
                <input className="form-control"
                  name="bic"
                  value={bic}
                  pattern='[A-Z]{6}[2-9A-Z][0-9A-NP-Z]([A-Z0-9]{3}|x{3})?'
                  onChange={this.onChange}
                  type="text"
                />
              </div> }
            </div>
          </div>

          {this.props.role === ROLES.SUPER_USER && <div className="form-row">
            <div className="col">
              <label><FormattedMessage id="app.signUp.commissionsToUser" defaultMessage="Commissions to user"/> (%)*</label>
              {commissionsArray}
              <button type="button" className="btn btn-success" onClick={this.onAddCommissions}><FormattedMessage id="app.signUp.addCommission" defaultMessage="Add commission"/></button>
            </div>
          </div> }

          {this.props.role === ROLES.USER_LEVEL_1 && <div className="form-row">
            <div className="col">
              <label><FormattedMessage id="app.signUp.commissionToUser" defaultMessage="Commission to user"/>*</label>
              <select class="custom-select" required name="commission" id="commission" onChange={this.onChange}>
                {commissionsSelect}
              </select>
            </div>
          </div> }
          

          {this.props.role !== ROLES.USER_LEVEL_2 && <div className="form-check">
            <input className="form-check-input"
              id="canCreateUser"
              name="canCreateUser"
              checked={canCreateUser}
              onChange={this.onChangeCheckbox}              
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="canCreateUser"><FormattedMessage id="app.signUp.canCreateUser<" defaultMessage="Can create user"/></label>
          </div>}



          <small id="fieldRequired" className="form-text text-muted">* <FormattedMessage id="app.signUp.fieldsRequired" defaultMessage="Fields required"/></small>


          <button className="btn btn-primary" disabled={isInvalid} type="submit">
          <FormattedMessage id="app.signUp.create" defaultMessage="Create"/>
          </button>

          {error && <p>{error.message}</p>}
        </form> : <p>loading...</p> }

      </div>
    );
  }
}

export { SignUpPage };


const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  withRouter,
  connect(
    mapStateToProps,
  ),
)(SignUpForm);