import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { DateRangePicker } from 'react-dates';
import { withAuthorization } from '../Session';
import { FormattedMessage } from 'react-intl';
import * as ROLES from '../../constants/roles';

class PotList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadPot();
  }

  loadPot() {
    this.setState({ loading: true });

    fetch(`${process.env.REACT_APP_BASE_URL}/pot/${this.props.uid}`, {
      headers: {
        'id-token': this.props.authUser.idToken,
      },
    }).finally(() => this.setState({isLoading: false}))
    .then(function (response) {
        return response.json();
    })
    .then(pot => {
      this.props.onSetModels(pot.users);
    })
    .finally(() => this.setState({loading: false}))
  }


  

  render() {
    let { models } = this.props;
    const { loading } = this.state;

    models = models.filter(model => {
      return (this.state.startDate ? model.createdAt.substring(0, 10) >= this.state.startDate.format('YYYY-MM-DD') : true) 
        && (this.state.endDate ? model.createdAt.substring(0, 10) <= this.state.endDate.format('YYYY-MM-DD') : true);
    }).sort(function(a, b) {
      return b.createdAt.localeCompare(a.createdAt)
    });

    let total = 0;
    if (models.length > 0) {
      models.map(model => {
        if ((true !== model.isRefunded && true !== model.isInTrialPeriod) || true === model.isPaid) {
          total+= model.amount}
        }
      );
    }
    
    return (
      <div>
        {loading ? <div>Loading ...</div> :
          <div>
              <h5>{models.length} <FormattedMessage id="app.pot.participations" defaultMessage="participation(s)"/></h5>

              <div>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start_date_id" // PropTypes.string.isRequired,
                displayFormat={() => "DD/MM/YYYY"}
                isOutsideRange={() => false}
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                    this.setState({ startDate, endDate });
                  }
                } // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              /><br/><br/>
            </div>


            <table className="table-responsive table table-striped">
              <thead className="thead-dark">
                  <tr>
                    <th><FormattedMessage id="app.pot.id" defaultMessage="ID"/></th>
                    <th><FormattedMessage id="app.pot.createdAt" defaultMessage="Created at"/></th>
                    <th><FormattedMessage id="app.pot.codeUsed" defaultMessage="Code used"/></th>
                    <th><FormattedMessage id="app.pot.email" defaultMessage="E-Mail"/></th>
                    <th><FormattedMessage id="app.pot.firstname" defaultMessage="Firstname"/></th>
                    <th><FormattedMessage id="app.pot.lastname" defaultMessage="Lastname"/></th>
                    { this.props.role !== ROLES.USER_LEVEL_2  && <th><FormattedMessage id="app.pot.state" defaultMessage="State"/></th> }
                    { this.props.role !== ROLES.USER_LEVEL_2  && <th><FormattedMessage id="app.pot.amount" defaultMessage="Amount"/></th> }
                  </tr>
              </thead>
              <tbody>
                {models.length === 0 && <tr><td colSpan="99"><FormattedMessage id="app.userList.noElement" defaultMessage="No element"/></td></tr>}
                {models.map(model => (
                  <tr key={model.uid}>
                    <td>
                      <a class="linkex" href={`https://app.mymotheragency.com/model-profiles/${model.uid}`} target="_blank"><img src="/external.png" />{model.uid}</a>
                    </td>
                    <td>
                      {model.createdAt}
                    </td>
                    <td>
                      {model.referral ? model.referral.code : null}
                    </td>
                    <td>
                      {model.email}
                    </td>
                    <td>
                      {model.firstName}
                    </td>
                    <td>
                      {model.lastName}
                    </td>
                    { this.props.role !== ROLES.USER_LEVEL_2  && <td>
                      {true === model.isRefunded && <span className="badge badge-secondary"><FormattedMessage id="app.pot.refunded" defaultMessage="Refunded"/></span> } 
                      {true === model.isInTrialPeriod && <span className="badge badge-secondary"><FormattedMessage id="app.pot.trialPeriod" defaultMessage="Trial period"/></span> } 
                      {(! model.hasOwnProperty('isPaid') || false === model.isPaid) && <span className="badge badge-secondary"><FormattedMessage id="app.pot.pending" defaultMessage="Pending"/></span> }
                      {true === model.isPaid && <span className="badge badge-secondary"><FormattedMessage id="app.pot.paid" defaultMessage="Paid"/></span> }
                    </td> }
                    { this.props.role !== ROLES.USER_LEVEL_2  &&  <td>
                      {(true === model.isRefunded || true === model.isInTrialPeriod) && true !== model.isPaid ? Number.parseFloat(0).toFixed(2) : Number.parseFloat(model.amount).toFixed(2)} &euro;
                    </td> }
                  </tr>
                ))}
              </tbody>
            </table>

                { this.props.role !== ROLES.USER_LEVEL_2  && models.length > 0 && <div class='totalnum'><span>Total:</span><span>{Number.parseFloat(total).toFixed(2)} &euro;</span></div>}
          </div> }
        </div>
    );
  }
}

const condition = authUser => !!authUser;

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  models: Object.keys(state.potState.models || {}).map(key => ({
    ...state.potState.models[key],
    uid: key,
  })),
});

const mapDispatchToProps = dispatch => ({
  onSetModels: models => dispatch({ type: 'MODELS_SET', models }),
});

export default compose(
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PotList);

