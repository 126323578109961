import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PotList from '../Pot'
import { withAuthorization } from '../Session';
import { FormattedMessage } from 'react-intl';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import {  Redirect } from 'react-router'

class PotPage extends Component {

  render() {
   
    return (
      <div>        
        {(this.props.authUser.role === ROLES.ADMIN || this.props.authUser.role === ROLES.ACCOUNTING) &&  <Redirect to={ROUTES.BALANCE_SHEET}/>}

        <h2><FormattedMessage id="app.account.pot" defaultMessage="Pot"/></h2>
        {this.props.authUser && <PotList uid={this.props.authUser.uid} role={this.props.authUser.role}/>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const condition = authUser => !!authUser;

export default compose(
  connect(
    mapStateToProps,
  ),
  withAuthorization(condition),
)(PotPage);
