import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import SignUpForm from '../SignUp';
import { FormattedMessage } from 'react-intl';

const CreateSuperUserPage = () => (
  <div>
    <h1><FormattedMessage id="app.superUser.createSuperUser" defaultMessage="Create super user"/></h1>
    <SignUpForm role={ROLES.SUPER_USER}/>
  </div>
);

const condition = authUser =>
  authUser && authUser.role === ROLES.ADMIN;

export default compose(
  withAuthorization(condition),
)(CreateSuperUserPage);
