import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import SignUpForm from '../SignUp';
import { FormattedMessage } from 'react-intl';

const CreateUserLevel1Page = () => (
  <div>
    <h1><FormattedMessage id="app.userLevel1.createUserLevel1" defaultMessage="Create user level 1"/></h1>
    <SignUpForm role={ROLES.USER_LEVEL_1}/>
  </div>
);

const condition = authUser =>
  authUser && authUser.role === ROLES.SUPER_USER;

export default compose(
  withAuthorization(condition),
)(CreateUserLevel1Page);
