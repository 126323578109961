import React from 'react';

import { withFirebase } from '../Firebase';
import { FormattedMessage } from 'react-intl';

const SignOutButton = ({ firebase }) => (
  <button type="button" className="btn btn-link" onClick={firebase.doSignOut}>
    <FormattedMessage id="app.signOut.signOut" defaultMessage="Sign Out"/>
  </button>
);

export default withFirebase(SignOutButton);
