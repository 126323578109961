import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import SignUpForm from '../SignUp';
import { FormattedMessage } from 'react-intl';

const CreateUserLevel2Page = () => (
  <div>
    <h1><FormattedMessage id="app.userLevel2.createUserLevel2" defaultMessage="Create user level 2"/></h1>
    <SignUpForm role={ROLES.USER_LEVEL_2}/>
  </div>
);

const condition = authUser =>
  authUser && authUser.role === ROLES.USER_LEVEL_1 && authUser.canCreateUser;

export default compose(
  withAuthorization(condition),
)(CreateUserLevel2Page);
