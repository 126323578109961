import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PotList from '../Pot'

import { withFirebase } from '../Firebase';
import { FormattedMessage } from 'react-intl';
import { withAuthorization } from '../Session';
import UserChildren from './UserChildren';
import * as ROLES from '../../constants/roles';

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    this.setState({ loading: true });

    fetch(`${process.env.REACT_APP_BASE_URL}/user/${this.props.match.params.id}`, {
      headers: {
        'id-token': this.props.authUser.idToken,
      },
    }).finally(() => this.setState({isLoading: false}))
    .then(function (response) {
        return response.json();
    })
    .then(user => {
      this.props.onSetUser(
        user.user,
        this.props.match.params.id,
      );
    })
    .finally(() => this.setState({loading: false}))
  }


  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.props.user.email);
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <h2><FormattedMessage id="app.userItem.user" defaultMessage="User"/> ({this.props.match.params.id})</h2>
        {loading && <div>Loading ...</div>}

        {user && (
          <div class="return">
            {user.parent && user.parent.uid && user.parent.uid !== this.props.authUser.uid && <a href={`/user/show/${user.parent.uid}`}><FormattedMessage id="app.userItem.backToParent" defaultMessage="Back to parent"/></a>}

            <div className="jumbotron">
              <div className="md-12"><h3>
                <strong><FormattedMessage id="app.userItem.user" defaultMessage="User"/>:</strong> {user.email}
              </h3></div>

              <div className="md-12"><h3>
                { user.parent && user.parent.amount > 0 && user.commission > 0 && <span><strong><FormattedMessage id="app.userItem.commission" defaultMessage="Commission"/>:</strong>{Number.parseFloat(Number.parseFloat(user.commission) * Number.parseFloat(user.parent.amount) / 100).toFixed(2)} &euro;</span> }
              </h3></div>

              <div className="md-12"><h3>
                { user.amount > 0 && <span><strong><FormattedMessage id="app.userItem.amount" defaultMessage="Amount"/>: </strong>{Number.parseFloat(user.amount).toFixed(2)} &euro;</span> }
              </h3></div>

              <div className="md-12">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onSendPasswordResetEmail}
                >
                  <FormattedMessage id="app.userItem.sendPasswordReset" defaultMessage="Send Password Reset"/>
                </button>
              </div>
            </div>
            
            <div className="md-12">
              <h2><FormattedMessage id="app.userItem.participations" defaultMessage="Participations"/></h2>
              <PotList uid={this.props.match.params.id} role={user.role}/>
            </div>

            {this.props.user.role !== ROLES.USER_LEVEL_2 && <div className="md-12">
              <h2><FormattedMessage id="app.userItem.users" defaultMessage="Users"/></h2>
              <UserChildren uid={this.props.match.params.id} parent={user}/>
            </div>}

          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  user: (state.userState.users || {})[props.match.params.id],
});

const mapDispatchToProps = dispatch => ({
  onSetUser: (user, uid) => dispatch({ type: 'USER_SET', user, uid }),
});

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserItem);
