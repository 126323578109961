const INITIAL_STATE = {
  models: null,
};

const applySetModels = (state, action) => ({
  ...state,
  models: action.models,
});

function potReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MODELS_SET': {
      return applySetModels(state, action);
    }
    default:
      return state;
  }
}

export default potReducer;
